<template>
  <div class="graduation-letter d-flex flex-column mt-3 mx-5" :style="format">
    <!-- HEADER SECTION -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="mr-2">
        <img
          :src="'https://edutech.albayan.sch.id:3010/images/1636073178566.png'"
          width="80px"
          style="max-height: 75px"
          alt
        />
      </div>
      <div class="text-center" style="font-size: 12px;">
        <div v-if="dataKopSurat.header" class="text-uppercase font-weight-bold mb-1">
          {{dataKopSurat.header.toUpperCase()}}
        </div>
        <div v-if="dataKopSurat.nama_sekolah" class="text-uppercase font-weight-bold mb-1">
          {{dataKopSurat.nama_sekolah.toUpperCase()}}
        </div>
        <div v-if="dataKopSurat.alamat">
          {{dataKopSurat.alamat.toUpperCase()}}
        </div>
        <div v-if="dataKopSurat.telepon || dataKopSurat.email">
          {{dataKopSurat.telepon ? `Telp. ${dataKopSurat.telepon}` : ""}} {{dataKopSurat.email ? `email. ${dataKopSurat.email}` : ""}}
        </div>
        <div v-if="dataKopSurat.kota" style="letter-spacing: 2px;">
          {{dataKopSurat.kota.toUpperCase()}}
        </div>
      </div>
      <div class="invisible">
        <img
          :src="`/${configSchool.logo}`"
          width="50px"
          alt
        />
      </div>
    </div>
    <!--  -->
    <div class="hr" />
    <!-- TITLE SECTION -->
    <div class="text-center" style="font-size: 12px;">
      <div class="text-uppercase font-weight-bold">
        Surat Keterangan Lulus
      </div>
      <div class="text-uppercase font-weight-bold">
        TAHUN PELAJARAN {{dataKopSurat.tahun_ajaran}}
      </div>
      <div>Nomor: {{dataKopSurat.nomor_skl}}</div>
    </div>
    <!--  -->
    <!-- INTRODUCTION SECTION -->
    <div class="font-size-11 mt-4">
      <div class="text-justify">
        Yang bertanda tangan di bawah ini, Kepala {{(dataKopSurat.nama_sekolah || '').toUpperCase()}} menerangkan dengan sesungguhnya bahwa
      </div>
      <div style="width: 600px; margin-left: 40px;" class="my-2">
        <div class="d-flex align-items-center mb-1" v-for="(student, i) in biodataMurid" :key="i">
          <div class="d-flex align-items-center w-50">
            <div class="mr-2">{{i + 1}}.</div>
            <div>{{student.text}}</div>
          </div>
          <div>: {{student.data}}</div>
        </div>
      </div>
      <div class="text-justify">
        adalah peserta Asesmen Sumatif Sekolah (ASS) {{(dataKopSurat.nama_sekolah || '').toUpperCase()}} {{capitalize(dataKopSurat.kota)}} Tahun Pelajaran {{dataKopSurat.tahun_ajaran}}, berdasarkan ketuntasan hasil pembelajaran, kriteria kelulusan sesuai dengan peraturan perundang-undangan dan hasil Rapat Dewan Pendidik, maka peserta didik tersebut diatas dinyatakan:
      </div>
    </div>
    <!--  -->
    <!-- GRADUATION STATUS (LULUS/TIDAK LULUS) -->
    <div class="my-2">
      <h3 class="text-center">LULUS</h3>
    </div>
    <div class="text-justify font-size-11">
      dengan hasil nilai sebagai berikut :
    </div>
    <!--  -->
    <!-- SUBJECT GRADE TABLE -->
    <div class="mt-2" style="width: 80%; margin-left: auto; margin-right: auto;">
      <table
      class="
        w-100
        table table-bordered table-responsive-sm
        font-size-11
        text-center
        primaryTable
      "
      >
        <tr>
          <td style="width: 8%;" class="align-middle font-weight-bold">
            <div class="py-2">No</div>
          </td>
          <td
            style="width: 77%"
            class="align-middle font-weight-bold"
          >
            Mata Pelajaran
          </td>
          <td style="width: 15%;" class="align-middle font-weight-bold">Nilai ASS</td>
        </tr>
        <template v-for="(group, i) in (dataNilai.nilai_mapel || [])">
          <tr class="text-left" :key="i + '-mapel group-' + group.nama_kelompok">
            <td colspan="13" class="font-weight-bold">Kelompok {{ group.nama_kelompok }}</td>
          </tr>
          <template v-for="(mapel, index1) in group.mata_pelajaran">
            <tr :key="'mapel-group-' + group.nama_kelompok + '-' + index1">
              <td class="align-middle">{{ numberIndex(index1) }}</td>
              <td class="text-left align-middle">
                <div
                  class="pl-1"
                  style="padding-top: 1px; padding-bottom: 1px !important"
                >
                  {{mapel.nama_mata_pelajaran}}
                </div>
              </td>
              <td class="align-middle">
                <template v-if="!mapel.mata_pelajaran">
                  {{
                    mapel.nilai ? Math.round(mapel.nilai) : '-'
                  }}
                </template>
              </td>
            </tr>
            <template v-if="mapel.mata_pelajaran">
              <tr
                v-for="(childMapel, i3) in mapel.mata_pelajaran"
                :key="'child-mapel-' + mapel.nama_mata_pelajaran + '-' + group.nama_kelompok + '-' + i3"
              >
                <td class="align-middle"></td>
                <td class="text-left align-middle">
                  <div
                    class="pl-1"
                    style="padding-top: 1px; padding-bottom: 1px !important"
                  >
                    {{letterIndex(i3)}}.
                    {{childMapel.nama_mata_pelajaran}}
                  </div>
                </td>
                <td class="align-middle">
                  {{
                    childMapel.nilai ? Math.round(childMapel.nilai) : '-'
                  }}
                </td>
              </tr>
            </template>
          </template>
        </template>
        <tr>
          <td colspan="2" class="font-weight-bold"><div class="py-1">RATA-RATA</div></td>
          <td><div class="py-1">{{Number(dataNilai.rata_rata).toFixed(2)}}</div></td>
        </tr>
      </table>
    </div>
    <!--  -->
    <!-- CLOSING SENETENCE -->
    <div class="font-size-11 my-3">
      Surat keterangan ini bersifat sementara dan dapat digunakan sebagai pengganti Ijazah sampai diterbitkan dokumen Ijazah aslinya
    </div>
    <!--  -->
    <!-- SIGN SETION -->
    <div
      class="
        ttd
        mt-auto
        font-size-11
        d-flex
        justify-content-between
        qrcode

      "
      style="margin-bottom: 30px;"
    >
      <img
        class="qrcode-image"
        v-if="base64QrCode"
        :src="base64QrCode"
        alt="qrcode"
      />
      <div style="min-width: 150px;">
        <div class="font-size-11">
          {{capitalize(dataKopSurat.kota)}}, {{dataKopSurat.tanggal_skl}}
        </div>
        <div class="font-size-11">Kepala Sekolah</div>
        <div
          class="mt-3 font-size-18"
        >
          <!-- <check-icon /> -->
          <div class="font-size-11">ttd</div>
        </div>
        <template>
          <div class="font-size-11 mt-3 pt-1">
            {{(dataKopSurat.nama_kepsek || '').toUpperCase()}}
          </div>
          <div class="font-size-11">
            NIP {{'--'}}
          </div>
        </template>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
// import moment from 'moment'
import QRCode from 'qrcode'
import { capitalize } from '@/helpers/general'
// const CheckIcon = () => import('@/components/app/CheckIcon')

export default {
  data() {
    return {
      base64QrCode: null,
      textCaption: null,
      baseUrl: window.location.origin,
    }
  },
  // components: { CheckIcon },
  props: {
    configSchool: {
      type: Object,
    },
    dataGraduationLetter: {
      type: Object,
      required: true,
    },
    format: {
      type: Object,
      // required: true,
    },
    // isWatermarkShow: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  mounted() {
    // console.log('children', this.dataGraduationLetter)
    this.generateBase64QrCode(`${this.dataGraduationLetter.uuid}`)
    // const guruWalas = this.dataGraduationLetter.nama_walikelas
    // const kepalaSekolah = this.dataGraduationLetter.nama_kepala_sekolah
    // this.textCaption = `This document was digitally signed by the principal${kepalaSekolah ? ` (${kepalaSekolah})` : ''}, and the class teacher${guruWalas ? ` (${guruWalas})` : ''}. No wet/physical signature required`
  },
  methods: {
    capitalize,
    async generateBase64QrCode(uuid) {
      try {
      // console.log(this.base64IsReady)
        const link = this.baseUrl + '/public/graduation-letter-validation/' + uuid
        this.base64QrCode = await QRCode.toDataURL(link)
      } catch (err) {
        console.log(err)
      }
    },
    numberIndex(number) {
      return ++number
    },
    floatNumber(number) {
      if (!number) return null
      return parseFloat(number).toFixed(2)
    },
    letterIndex(number) {
      return String.fromCharCode(97 + number)
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    styleContainer() {
      if (this.format) {
        return { ...this.format }
      }
      return {}
    },
    dataMurid() {
      return this.dataGraduationLetter?.data_murid || {}
    },
    dataNilai() {
      return this.dataGraduationLetter?.data_nilai || {}
    },
    dataKopSurat() {
      return this.dataGraduationLetter?.data_kop_surat || {}
    },
    biodataMurid() {
      return [
        { text: 'Nama', data: this.dataMurid.nama },
        { text: 'Tempat dan Tanggal Lahir', data: this.dataMurid.ttl },
        { text: 'NIS/NISN', data: `${this.dataMurid.nis} / ${this.dataMurid.nisn}` },
        { text: 'No. Peserta', data: this.dataMurid.nomor_peserta },
      ]
    },
    // noTelp() {
    //   let noTelp = this.institusi?.telepon
    //   if (this.institusi?.telepon) {
    //     const daerah = noTelp.slice(0, 3)
    //     noTelp = `(${daerah}) ${noTelp.slice(3)}`
    //   }
    //   return noTelp
    // },
    // noFax() {
    //   let noFax = this.institusi?.fax
    //   if (this.institusi?.fax) {
    //     const daerah = noFax.slice(0, 3)
    //     noFax = `(${daerah}) ${noFax.slice(3)}`
    //   }
    //   return noFax
    // },
  },
}
</script>

<style>
.checkVerification {
  margin: 0;
}
.checkVerification:disabled {
  cursor: default;
}

.graduation-letter .primaryTable {
  margin-bottom: 5px !important;
  position: relative;
  z-index: 1;
}

.graduation-letter .table td {
  padding: 1px !important;
  padding-top: 0.5px!important;
  padding-bottom: 0.5px!important;
}
.graduation-letter .hr {
  border: 0.5px solid black !important;
  padding: 1px;
  margin: 10px 0px;
}

.graduation-letter .font-size-11 {
  font-size: 11px;
}

.table-bordered {
  border: 0.5px solid black;
}

.table-bordered td {
  border: 0.5px solid black;
}

.borderless {
  border: none !important;
}

.borderless td {
  border: none !important;
}

.ttd .qrcode-image {
  min-width: 50px;
  min-height: 50px;
  max-width: 100px;
  max-height: 100px;
}

@media (min-width: 768px) {
  .ttd .qrcode-image {
    width: 100px;
    height: 100px;
  }
}

/* .watermark {
  position: absolute;
  z-index: 10;
  display: block;
  min-height: 50%;
  min-width: 50%;
}

.watermark-text {
  padding: 20%;
  color: lightgrey;
  font-size: 100px;
  transform: rotate(300deg);
  -webkit-transform: rotate(300deg);
} */
</style>
